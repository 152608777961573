@mixin bs-callout($background, $border, $color) {
  //@include gradient-bg($background);
  background-color: $background;
  border-color: $border;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color;
  }

  hr {
    border-top-color: darken($border, 5%);
  }

  .callout-link {
    color: (darken($color, 10%));
  }

}
