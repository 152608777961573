.bs-callout {
  padding: $callout-padding-y $callout-padding-x;
  margin-bottom: $callout-margin-bottom;
  border: $callout-border-width solid transparent;
  border-left-width: $callout-border-left-width;
  @include border-radius($callout-border-radius);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code,
  .highlight {
    background-color: #fff;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .bs-callout-#{$color} {
    @include bs-callout($gray-100, theme-color-level($color, $callout-border-level), theme-color-level($color, $callout-color-level));
  }
}
